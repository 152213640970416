<template>
  <div>
    <van-nav-bar :left-arrow="true" left-text="返回" title="提现记录"  fixed :placeholder="true" @click-left="goBack">
    <template #right v-if="amount > 0">
      <span style="color: #ff0000;font-size: x-small;">待提:{{amount}}¥</span>
      <van-button style="margin-left: 10px;"  type="info" size="mini" @click="withdraw">申请提现</van-button>
    </template>
    </van-nav-bar>
    <div class="home-wrap" >
      <div class="main-card-wrap" v-for="item in tableData" >
        <div style="border-bottom: 1px  dashed #ccc;margin-bottom: 10px">
          <van-cell center :title="'申请号：'+item.ID"  >
            <template #right-icon>
              <span style="color: #ff0000;font-size: x-small;">金额：{{ item.amount }}</span>
            </template>
          </van-cell>
          <van-cell>
              <span style="color: #11c62f;font-size: x-small;">{{ item.status_text }}</span>
          </van-cell>
        </div>
      </div>
    </div>
    
  </div>
  
</template>

<script>
import Api from '@/api-service'
export default {
  name: "Rule-Detail",
  data(){
    return {
      amount:0.0,
      tableData:[],
      
    }
  },
  created() {
    this.amount = this.$route.query.amount;
    this.fetchData();
  },
  methods:{
    fetchData(){
      Api.getWithdrawList({}).then(res=>{
        this.tableData = res.list;
        // console.log(this.tableData)
      })
    },
    withdraw(){
      let data = {
        amount:this.amount,
      }

      Api.applyWithdraw(data).then(res=>{
        this.$notify({type:'success',message:'申请成功'});
        this.amount = 0.0
        this.fetchData();
      })
    },
    goBack(){
      this.$router.go(-1);
    },
   
  }
}
</script>

<style scoped lang="scss">
.home-wrap {
  height: 100%;
  background-color: #f3f3f3;

  .main-card-wrap {
    background-color: white;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc;
    box-shadow: 2px 2px 3px #ccc;
    .left {
      .title {
        font-size: 16px;
      }
      .btn {
        height: 34px;
        width: 120px;
        color: white;
        margin-top: 6px;
        border-radius: 4px;
        background-color: #8565bd;
      }
    }
    .right {
      margin-right: 8px
    }
  }
}
</style>
